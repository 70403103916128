import { NgModule } from "@angular/core";
import { PreloadAllModules, RouterModule, UrlSerializer } from "@angular/router";
import { AuthGuard, LowercaseUrlSerializer } from "@fd/core";
import { HomeComponent } from "./components/home/home.component";
import { LoginComponent } from "./components/login/login.component";
import { NotAuthorizedComponent } from "./components/not-authorized/not-authorized.component";
import { NotFoundComponent } from "./components/not-found/not-found.component";
import { RedirectComponent } from "./components/redirect/redirect.component";
import { AppRoutes } from "./routes";
import { AuthService } from "./modules/shared/services/auth.service";

@NgModule({
  imports: [
    RouterModule.forRoot(
      [
        {
          path: "",
          component: RedirectComponent,
          canActivate: [AuthGuard],
          pathMatch: "full",
        },

        ...AppRoutes.home.toRoutes(HomeComponent),
        ...AppRoutes.login.toRoutes(LoginComponent),
        ...AppRoutes.notAuthorized.toRoutes(NotAuthorizedComponent),

        // lazy-loaded modules
        AppRoutes.frameworkRoot.toLazyRoute(() =>
          import("./modules/framework/framework.module").then(m => m.FrameworkModule)
        ),
        AppRoutes.accountRoot.toLazyRoute(() => import("./modules/account/account.module").then(m => m.AccountModule)),
        AppRoutes.adminRoot.toLazyRoute(() => import("./modules/admin/admin.module").then(m => m.AdminModule)),
        AppRoutes.accountRoot.toLazyRoute(() => import("./modules/account/account.module").then(m => m.AccountModule)),
        AppRoutes.payrollRoot.toLazyRoute(() => import("./modules/payroll/payroll.module").then(m => m.PayrollModule)),
        AppRoutes.supportServiceMetricsRoot.toLazyRoute(() =>
          import("./modules/support-service-metrics/support-service-metrics.module").then(
            m => m.SupportServiceMetricsModule
          )
        ),
        AppRoutes.timeOffRoot.toLazyRoute(() =>
          import("./modules/time-off/time-off.module").then(m => m.TimeOffModule)
        ),
        AppRoutes.shirtSizeRoot.toLazyRoute(() =>
          import("./modules/shirt-size/shirt-size.module").then(m => m.ShirtSizeModule)
        ),
        AppRoutes.teamRoot.toLazyRoute(() => import("./modules/team/team.module").then(m => m.TeamModule)),
        AppRoutes.clientToolsRoot.toLazyRoute(() =>
          import("./modules/client-tools/client-tools.module").then(m => m.ClientToolsModule)
        ),
        AppRoutes.scheduleOneRoot.toLazyRoute(() =>
          import("./modules/schedule-one/schedule-one.module").then(m => m.ScheduleOneModule)
        ),
        AppRoutes.shippingRoot.toLazyRoute(() =>
          import("./modules/shipping/shipping.module").then(m => m.ShippingModule)
        ),
        AppRoutes.cdsRoot.toLazyRoute(() => import("./modules/cds/cds.module").then(m => m.CDSModule)),
        AppRoutes.cprRoot.toLazyRoute(() => import("./modules/cpr/cpr.module").then(m => m.CprModule)),
        AppRoutes.qsdRoot.toLazyRoute(() => import("./modules/qsd/qsd.module").then(m => m.QSDModule)),
        AppRoutes.qualityAuditsRoot.toLazyRoute(() =>
          import("./modules/quality-audits/quality-audits.module").then(m => m.QualityAuditsModule)
        ),
        AppRoutes.foxRoot.toLazyRoute(() => import("./modules/fox/fox.module").then(m => m.FoxModule)),
        AppRoutes.mrpRoot.toLazyRoute(() => import("./modules/mrp/mrp.module").then(m => m.MRPModule)),
        AppRoutes.stationOwnerAuditsRoot.toLazyRoute(() =>
          import("./modules/station-owner-audits/station-owner-audits.module").then(m => m.StationOwnerAuditsModule)
        ),
        {
          path: "**",
          component: NotFoundComponent,
          data: { title: "Page Not Found" },
        },
      ],
      {
        // PreloadAllModules loads all the lazy loaded modules asynchronously on page load
        preloadingStrategy: PreloadAllModules,
      }
    ),
  ],
  exports: [RouterModule],
  providers: [AuthService, AuthGuard, { provide: UrlSerializer, useClass: LowercaseUrlSerializer }],
})
export class AppRoutingModule {}
